<template>
  <div class="app-container app-theme-white">
    <div class="app-main">
      <div class="app-main__outer">
        <div class="app-main__inner">
          <div class="d-flex justify-content-center align-items-center">
            <div class="logo-src" :class="logoClass" style="width:300px;height:48px"></div>
          </div>
          <div class="tab-content mt-4">
            <div
              class="tab-pane tabs-animation fade show active"
              id="tab-content-0"
              role="tabpanel"
            >
              <div class="row">
                <div class="col-md-6 offset-md-3 col-12">
                  <div>
                    <CardForm
                      :titulo="'Recuperar contraseña'"
                      @enviarForm="guardar"
                      :button="true"
                    >
                      <float-label
                        label="Nueva contraseña:"
                        clase="col-md-6 offset-md-3 col-12"
                        id="newpassword"
                        placeholder="Nueva contraseña"
                        type="password"
                        classInput="form-control"
                        v-model="user.newpassword"
                        :required="enviado && !$v.user.newpassword.required"
                      >
                      </float-label>
                      <float-label
                        label="Repetir nueva contraseña:"
                        clase="col-md-6 offset-md-3 col-12"
                        id="confirmpassword"
                        placeholder="Repetir nueva contraseña"
                        type="password"
                        :required="enviado && !$v.user.confirmpassword.required"
                        v-model="user.confirmpassword"
                        :classInput="'form-control ' + classConfirmPassword"
                      >
                        <template slot="validation">
                          <div
                            class="invalid-feedback"
                            v-if="enviado && !validatePassword"
                          >
                            Las contraseñas deben coincidir.
                          </div>
                        </template>
                      </float-label>
                      <div class="col-md-6 offset-md-3 col-12">
                        <span
                          ><i
                            style="width: 20px"
                            :class="'text-center fas ' + classMinPassword"
                          ></i>
                          Mínimo 8 caracteres</span
                        >
                      </div>
                      <div class="col-md-6 offset-md-3 col-12">
                        <span
                          ><i
                            style="width: 20px"
                            :class="'text-center fas ' + classNumPassword"
                          ></i>
                          Contiene números</span
                        >
                      </div>
                      <div class="col-md-6 offset-md-3 col-12">
                        <span
                          ><i
                            style="width: 20px"
                            :class="'text-center fas ' + classMayusPassword"
                          ></i>
                          Contiene mayúsculas</span
                        >
                      </div>
                      <div class="col-md-6 offset-md-3 col-12">
                        <span
                          ><i
                            style="width: 20px"
                            :class="'text-center fas ' + classSamePassword"
                          ></i>
                          Coincide la nueva contraseña</span
                        >
                      </div>
                    </CardForm>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FloatLabel from "@/components/layouts/FloatLabel";
import CardForm from "@/components/layouts/CardForm";
import { required } from "vuelidate/lib/validators";
import Axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
export default {
  name: "EditarUsuario",
  components: {
    FloatLabel,
    CardForm,
  },
  props: {
    iconoTitulo: String,
    titleCard: String,
    toCreateAction: String,
    toCreateText: String,
  },
  validations: {
    user: {
      newpassword: { required },
      confirmpassword: { required },
    },
  },
  data() {
    return {
      enviado: false,
      user: {
        id: null,
        newpassword: null,
        confirmpassword: null,
      },
    };
  },
  computed: {
    logoClass: () => {
      if (window.location.hostname == "recibos.pampadev.com.ar") {
        return ' desarrollo ';
      } else if (window.location.hostname == "hma.misrecibosdigitales.com.ar") {
        return ' ima ';
      } else if (
        window.location.hostname == "isnsl.misrecibosdigitales.com.ar"
      ) {
        return ' isnsl ';
      } else if (
        window.location.hostname == "diocesis.misrecibosdigitales.com.ar"
      ) {
        return " diocesis ";
      } else if (
        window.location.hostname == "isjb.misrecibosdigitales.com.ar"
      ) {
        return " isjb ";
      } else {
        return ' desarrollo ';
      }
    },
    validatePassword: function () {
      if (
        this.user.confirmpassword != "" &&
        this.user.confirmpassword == this.user.newpassword
      ) {
        return true;
      } else {
        return false;
      }
    },
    classConfirmPassword: function () {
      if (this.enviado && !this.validatePassword) {
        return "is-invalid";
      } else {
        return "";
      }
    },
    classMinPassword: function () {
      let newpass = this.user.newpassword
        ? this.user.newpassword.toString()
        : "";
      if (newpass != null && newpass != "") {
        if (newpass.length < 8) {
          return "text-danger fa-times";
        } else {
          return "text-success fa-check";
        }
      } else {
        return "fa-asterisk";
      }
    },
    classNumPassword: function () {
      let newpass = this.user.newpassword
        ? this.user.newpassword.toString()
        : "";
      let hasNumber = /\d/;
      if (newpass != null && newpass != "") {
        if (hasNumber.test(newpass)) {
          return "text-success fa-check";
        } else {
          return "text-danger fa-times";
        }
      } else {
        return "fa-asterisk";
      }
    },
    classMayusPassword: function () {
      let newpass = this.user.newpassword
        ? this.user.newpassword.toString()
        : "";
      if (newpass != null && newpass != "") {
        if (/[A-Z]/g.test(newpass)) {
          return "text-success fa-check";
        } else {
          return "text-danger fa-times";
        }
      } else {
        return "fa-asterisk";
      }
    },
    classSamePassword: function () {
      let newpass = this.user.newpassword
        ? this.user.newpassword.toString()
        : "";
      let confirmpass = this.user.confirmpassword
        ? this.user.confirmpassword.toString()
        : "";
      if (newpass != null && newpass != "") {
        if (newpass === confirmpass) {
          return "text-success fa-check";
        } else {
          return "text-danger fa-times";
        }
      } else {
        return "fa-asterisk";
      }
    },
    send: function () {
      let vacio = "fa-asterisk";
      let error = "text-danger fa-times";
      if (
        this.classMinPassword == vacio ||
        this.classMinPassword == error ||
        this.classNumPassword == vacio ||
        this.classNumPassword == error ||
        this.classMayusPassword == vacio ||
        this.classMayusPassword == error ||
        this.classSamePassword == vacio ||
        this.classSamePassword == error
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    let userid = this.$route.params.user_id;
    this.user.id = atob(userid);
  },
  methods: {
    ...mapActions({
      loginAction: "login"
    }),
    guardar() {
      this.enviado = true;
      this.$v.$touch();
      if (this.$v.$invalid || !this.send) {
        return false;
      }
      Axios.post("recuperar-password/", this.user)
        .then((res) => {
          if (res.data.success) {
            Swal.fire({
              icon: "success",
              title: "Éxito",
              text: `Su contraseña se ha cambiado con éxito.`,
            });
            let usuario = {...res.data.user};
            usuario.password = this.user.newpassword;
            this.loginAction(usuario)
              .then((r) => {
                console.log(r);
                console.log(localStorage.getItem("token"));
                this.$router.push("/");
              })
              .catch((e) => {
                console.log(e);
                Swal.fire({
                  icon: "error",
                  title: "Oopss..",
                  text: `El usuario y la contraseña no son validas.`,
                });
              });
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: `${res.data.mensaje}`,
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `No se ha podido guardar exitosamente.
            ${err}`,
          });
          console.log(err);
        });
    },
  },
};
</script>
